body {
    margin: 0;
    background-color: #f8f9fa;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem;
}

.riskapp-logo {
    width: 15rem;
    transition: 0.3s;
}

.enter-meeting-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.message-validation {
    color: red
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 441px) and (orientation: portrait) {
    .riskapp-logo {
        width: 12rem;
    }
}

@media screen and (max-height: 441px) and (orientation: landscape) {
    .riskapp-logo {
        width: 12rem;
    }
}

@media screen and (max-width: 375px) and (orientation: portrait) {
}

@media screen and (max-height: 375px) and (orientation: landscape) {
    .riskapp-logo {
        width: 10rem;
    }
}

button.ant-btn-primary {
    color: #fff;
    background: #0d6efd;
    border-radius: 8px;
}

.ant-alert-error {
    background-color: #fff !important;
    border: none !important;
}