.inspector-container-external-wrapper {
    left: 25%;
    top: 20%;
    height: auto;
    width: auto;
    position: absolute;
    padding: 0;
    z-index: 9999;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: rgb(248, 249, 250);
}

.inspector-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.inspector-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid darkgrey;
    padding: 1rem;
    cursor: move;
}

.icon-copy-link {
    cursor: pointer;
}

.inspector-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    /*overflow: auto;
    resize: both;
    height: 60vh;
    width: 50vw;*/
}

.inspector-close,
.inspector-icons-index {
    color: lightgrey;
    transition: 0.5s;
    font-size: 1.1em;
    cursor: pointer;
}

.inspector-close:hover,
.inspector-close:active,
.inspector-icons-index:hover,
.inspector-icons-index-selected {
    color: black;
    transition: 0.35s;
}

.inspector-icons-index,
.inspector-icons-index-selected {
    font-size: 2em;
    margin: 0 1rem 1rem 0;
}

.inspector-select-attendee-mode {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 2rem;
}

.inspector-select-text {
    font-size: 1.2em;
    color: black;
}


@font-face {
    font-family: "Glyphicons Halflings";
    src: url("./fonts/glyphicons-halflings-regular.eot");
    src: url("./fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/glyphicons-halflings-regular.woff2") format("woff2"),
    url("./fonts/glyphicons-halflings-regular.woff") format("woff"),
    url("./fonts/glyphicons-halflings-regular.ttf") format("truetype"),
    url("./fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphicon-asterisk:before {
    content: "\002a";
}

.glyphicon-plus:before {
    content: "\002b";
}

.glyphicon-euro:before,
.glyphicon-eur:before {
    content: "\20ac";
}

.glyphicon-minus:before {
    content: "\2212";
}

.glyphicon-cloud:before {
    content: "\2601";
}

.glyphicon-envelope:before {
    content: "\2709";
}

.glyphicon-pencil:before {
    content: "\270f";
}

.glyphicon-glass:before {
    content: "\e001";
}

.glyphicon-music:before {
    content: "\e002";
}

.glyphicon-search:before {
    content: "\e003";
}

.glyphicon-heart:before {
    content: "\e005";
}

.glyphicon-star:before {
    content: "\e006";
}

.glyphicon-star-empty:before {
    content: "\e007";
}

.glyphicon-user:before {
    content: "\e008";
}

.glyphicon-film:before {
    content: "\e009";
}

.glyphicon-th-large:before {
    content: "\e010";
}

.glyphicon-th:before {
    content: "\e011";
}

.glyphicon-th-list:before {
    content: "\e012";
}

.glyphicon-ok:before {
    content: "\e013";
}

.glyphicon-remove:before {
    content: "\e014";
}

.glyphicon-zoom-in:before {
    content: "\e015";
}

.glyphicon-zoom-out:before {
    content: "\e016";
}

.glyphicon-off:before {
    content: "\e017";
}

.glyphicon-signal:before {
    content: "\e018";
}

.glyphicon-cog:before {
    content: "\e019";
}

.glyphicon-trash:before {
    content: "\e020";
}

.glyphicon-home:before {
    content: "\e021";
}

.glyphicon-file:before {
    content: "\e022";
}

.glyphicon-time:before {
    content: "\e023";
}

.glyphicon-road:before {
    content: "\e024";
}

.glyphicon-download-alt:before {
    content: "\e025";
}

.glyphicon-download:before {
    content: "\e026";
}

.glyphicon-upload:before {
    content: "\e027";
}

.glyphicon-inbox:before {
    content: "\e028";
}

.glyphicon-play-circle:before {
    content: "\e029";
}

.glyphicon-repeat:before {
    content: "\e030";
}

.glyphicon-refresh:before {
    content: "\e031";
}

.glyphicon-list-alt:before {
    content: "\e032";
}

.glyphicon-lock:before {
    content: "\e033";
}

.glyphicon-flag:before {
    content: "\e034";
}

.glyphicon-headphones:before {
    content: "\e035";
}

.glyphicon-volume-off:before {
    content: "\e036";
}

.glyphicon-volume-down:before {
    content: "\e037";
}

.glyphicon-volume-up:before {
    content: "\e038";
}

.glyphicon-qrcode:before {
    content: "\e039";
}

.glyphicon-barcode:before {
    content: "\e040";
}

.glyphicon-tag:before {
    content: "\e041";
}

.glyphicon-tags:before {
    content: "\e042";
}

.glyphicon-book:before {
    content: "\e043";
}

.glyphicon-bookmark:before {
    content: "\e044";
}

.glyphicon-print:before {
    content: "\e045";
}

.glyphicon-camera:before {
    content: "\e046";
}

.glyphicon-font:before {
    content: "\e047";
}

.glyphicon-bold:before {
    content: "\e048";
}

.glyphicon-italic:before {
    content: "\e049";
}

.glyphicon-text-height:before {
    content: "\e050";
}

.glyphicon-text-width:before {
    content: "\e051";
}

.glyphicon-align-left:before {
    content: "\e052";
}

.glyphicon-align-center:before {
    content: "\e053";
}

.glyphicon-align-right:before {
    content: "\e054";
}

.glyphicon-align-justify:before {
    content: "\e055";
}

.glyphicon-list:before {
    content: "\e056";
}

.glyphicon-indent-left:before {
    content: "\e057";
}

.glyphicon-indent-right:before {
    content: "\e058";
}

.glyphicon-facetime-video:before {
    content: "\e059";
}

.glyphicon-picture:before {
    content: "\e060";
}

.glyphicon-map-marker:before {
    content: "\e062";
}

.glyphicon-adjust:before {
    content: "\e063";
}

.glyphicon-tint:before {
    content: "\e064";
}

.glyphicon-edit:before {
    content: "\e065";
}

.glyphicon-share:before {
    content: "\e066";
}

.glyphicon-check:before {
    content: "\e067";
}

.glyphicon-move:before {
    content: "\e068";
}

.glyphicon-step-backward:before {
    content: "\e069";
}

.glyphicon-fast-backward:before {
    content: "\e070";
}

.glyphicon-backward:before {
    content: "\e071";
}

.glyphicon-play:before {
    content: "\e072";
}

.glyphicon-pause:before {
    content: "\e073";
}

.glyphicon-stop:before {
    content: "\e074";
}

.glyphicon-forward:before {
    content: "\e075";
}

.glyphicon-fast-forward:before {
    content: "\e076";
}

.glyphicon-step-forward:before {
    content: "\e077";
}

.glyphicon-eject:before {
    content: "\e078";
}

.glyphicon-chevron-left:before {
    content: "\e079";
}

.glyphicon-chevron-right:before {
    content: "\e080";
}

.glyphicon-plus-sign:before {
    content: "\e081";
}

.glyphicon-minus-sign:before {
    content: "\e082";
}

.glyphicon-remove-sign:before {
    content: "\e083";
}

.glyphicon-ok-sign:before {
    content: "\e084";
}

.glyphicon-question-sign:before {
    content: "\e085";
}

.glyphicon-info-sign:before {
    content: "\e086";
}

.glyphicon-screenshot:before {
    content: "\e087";
}

.glyphicon-remove-circle:before {
    content: "\e088";
}

.glyphicon-ok-circle:before {
    content: "\e089";
}

.glyphicon-ban-circle:before {
    content: "\e090";
}

.glyphicon-arrow-left:before {
    content: "\e091";
}

.glyphicon-arrow-right:before {
    content: "\e092";
}

.glyphicon-arrow-up:before {
    content: "\e093";
}

.glyphicon-arrow-down:before {
    content: "\e094";
}

.glyphicon-share-alt:before {
    content: "\e095";
}

.glyphicon-resize-full:before {
    content: "\e096";
}

.glyphicon-resize-small:before {
    content: "\e097";
}

.glyphicon-exclamation-sign:before {
    content: "\e101";
}

.glyphicon-gift:before {
    content: "\e102";
}

.glyphicon-leaf:before {
    content: "\e103";
}

.glyphicon-fire:before {
    content: "\e104";
}

.glyphicon-eye-open:before {
    content: "\e105";
}

.glyphicon-eye-close:before {
    content: "\e106";
}

.glyphicon-warning-sign:before {
    content: "\e107";
}

.glyphicon-plane:before {
    content: "\e108";
}

.glyphicon-calendar:before {
    content: "\e109";
}

.glyphicon-random:before {
    content: "\e110";
}

.glyphicon-comment:before {
    content: "\e111";
}

.glyphicon-magnet:before {
    content: "\e112";
}

.glyphicon-chevron-up:before {
    content: "\e113";
}

.glyphicon-chevron-down:before {
    content: "\e114";
}

.glyphicon-retweet:before {
    content: "\e115";
}

.glyphicon-shopping-cart:before {
    content: "\e116";
}

.glyphicon-folder-close:before {
    content: "\e117";
}

.glyphicon-folder-open:before {
    content: "\e118";
}

.glyphicon-resize-vertical:before {
    content: "\e119";
}

.glyphicon-resize-horizontal:before {
    content: "\e120";
}

.glyphicon-hdd:before {
    content: "\e121";
}

.glyphicon-bullhorn:before {
    content: "\e122";
}

.glyphicon-bell:before {
    content: "\e123";
}

.glyphicon-certificate:before {
    content: "\e124";
}

.glyphicon-thumbs-up:before {
    content: "\e125";
}

.glyphicon-thumbs-down:before {
    content: "\e126";
}

.glyphicon-hand-right:before {
    content: "\e127";
}

.glyphicon-hand-left:before {
    content: "\e128";
}

.glyphicon-hand-up:before {
    content: "\e129";
}

.glyphicon-hand-down:before {
    content: "\e130";
}

.glyphicon-circle-arrow-right:before {
    content: "\e131";
}

.glyphicon-circle-arrow-left:before {
    content: "\e132";
}

.glyphicon-circle-arrow-up:before {
    content: "\e133";
}

.glyphicon-circle-arrow-down:before {
    content: "\e134";
}

.glyphicon-globe:before {
    content: "\e135";
}

.glyphicon-wrench:before {
    content: "\e136";
}

.glyphicon-tasks:before {
    content: "\e137";
}

.glyphicon-filter:before {
    content: "\e138";
}

.glyphicon-briefcase:before {
    content: "\e139";
}

.glyphicon-fullscreen:before {
    content: "\e140";
}

.glyphicon-dashboard:before {
    content: "\e141";
}

.glyphicon-paperclip:before {
    content: "\e142";
}

.glyphicon-heart-empty:before {
    content: "\e143";
}

.glyphicon-link:before {
    content: "\e144";
}

.glyphicon-phone:before {
    content: "\e145";
}

.glyphicon-pushpin:before {
    content: "\e146";
}

.glyphicon-usd:before {
    content: "\e148";
}

.glyphicon-gbp:before {
    content: "\e149";
}

.glyphicon-sort:before {
    content: "\e150";
}

.glyphicon-sort-by-alphabet:before {
    content: "\e151";
}

.glyphicon-sort-by-alphabet-alt:before {
    content: "\e152";
}

.glyphicon-sort-by-order:before {
    content: "\e153";
}

.glyphicon-sort-by-order-alt:before {
    content: "\e154";
}

.glyphicon-sort-by-attributes:before {
    content: "\e155";
}

.glyphicon-sort-by-attributes-alt:before {
    content: "\e156";
}

.glyphicon-unchecked:before {
    content: "\e157";
}

.glyphicon-expand:before {
    content: "\e158";
}

.glyphicon-collapse-down:before {
    content: "\e159";
}

.glyphicon-collapse-up:before {
    content: "\e160";
}

.glyphicon-log-in:before {
    content: "\e161";
}

.glyphicon-flash:before {
    content: "\e162";
}

.glyphicon-log-out:before {
    content: "\e163";
}

.glyphicon-new-window:before {
    content: "\e164";
}

.glyphicon-record:before {
    content: "\e165";
}

.glyphicon-save:before {
    content: "\e166";
}

.glyphicon-open:before {
    content: "\e167";
}

.glyphicon-saved:before {
    content: "\e168";
}

.glyphicon-import:before {
    content: "\e169";
}

.glyphicon-export:before {
    content: "\e170";
}

.glyphicon-send:before {
    content: "\e171";
}

.glyphicon-floppy-disk:before {
    content: "\e172";
}

.glyphicon-floppy-saved:before {
    content: "\e173";
}

.glyphicon-floppy-remove:before {
    content: "\e174";
}

.glyphicon-floppy-save:before {
    content: "\e175";
}

.glyphicon-floppy-open:before {
    content: "\e176";
}

.glyphicon-credit-card:before {
    content: "\e177";
}

.glyphicon-transfer:before {
    content: "\e178";
}

.glyphicon-cutlery:before {
    content: "\e179";
}

.glyphicon-header:before {
    content: "\e180";
}

.glyphicon-compressed:before {
    content: "\e181";
}

.glyphicon-earphone:before {
    content: "\e182";
}

.glyphicon-phone-alt:before {
    content: "\e183";
}

.glyphicon-tower:before {
    content: "\e184";
}

.glyphicon-stats:before {
    content: "\e185";
}

.glyphicon-sd-video:before {
    content: "\e186";
}

.glyphicon-hd-video:before {
    content: "\e187";
}

.glyphicon-subtitles:before {
    content: "\e188";
}

.glyphicon-sound-stereo:before {
    content: "\e189";
}

.glyphicon-sound-dolby:before {
    content: "\e190";
}

.glyphicon-sound-5-1:before {
    content: "\e191";
}

.glyphicon-sound-6-1:before {
    content: "\e192";
}

.glyphicon-sound-7-1:before {
    content: "\e193";
}

.glyphicon-copyright-mark:before {
    content: "\e194";
}

.glyphicon-registration-mark:before {
    content: "\e195";
}

.glyphicon-cloud-download:before {
    content: "\e197";
}

.glyphicon-cloud-upload:before {
    content: "\e198";
}

.glyphicon-tree-conifer:before {
    content: "\e199";
}

.glyphicon-tree-deciduous:before {
    content: "\e200";
}

.glyphicon-cd:before {
    content: "\e201";
}

.glyphicon-save-file:before {
    content: "\e202";
}

.glyphicon-open-file:before {
    content: "\e203";
}

.glyphicon-level-up:before {
    content: "\e204";
}

.glyphicon-copy:before {
    content: "\e205";
}

.glyphicon-paste:before {
    content: "\e206";
}

.glyphicon-alert:before {
    content: "\e209";
}

.glyphicon-equalizer:before {
    content: "\e210";
}

.glyphicon-king:before {
    content: "\e211";
}

.glyphicon-queen:before {
    content: "\e212";
}

.glyphicon-pawn:before {
    content: "\e213";
}

.glyphicon-bishop:before {
    content: "\e214";
}

.glyphicon-knight:before {
    content: "\e215";
}

.glyphicon-baby-formula:before {
    content: "\e216";
}

.glyphicon-tent:before {
    content: "\26fa";
}

.glyphicon-blackboard:before {
    content: "\e218";
}

.glyphicon-bed:before {
    content: "\e219";
}

.glyphicon-apple:before {
    content: "\f8ff";
}

.glyphicon-erase:before {
    content: "\e221";
}

.glyphicon-hourglass:before {
    content: "\231b";
}

.glyphicon-lamp:before {
    content: "\e223";
}

.glyphicon-duplicate:before {
    content: "\e224";
}

.glyphicon-piggy-bank:before {
    content: "\e225";
}

.glyphicon-scissors:before {
    content: "\e226";
}

.glyphicon-bitcoin:before {
    content: "\e227";
}

.glyphicon-btc:before {
    content: "\e227";
}

.glyphicon-xbt:before {
    content: "\e227";
}

.glyphicon-yen:before {
    content: "\00a5";
}

.glyphicon-jpy:before {
    content: "\00a5";
}

.glyphicon-ruble:before {
    content: "\20bd";
}

.glyphicon-rub:before {
    content: "\20bd";
}

.glyphicon-scale:before {
    content: "\e230";
}

.glyphicon-ice-lolly:before {
    content: "\e231";
}

.glyphicon-ice-lolly-tasted:before {
    content: "\e232";
}

.glyphicon-education:before {
    content: "\e233";
}

.glyphicon-option-horizontal:before {
    content: "\e234";
}

.glyphicon-option-vertical:before {
    content: "\e235";
}

.glyphicon-menu-hamburger:before {
    content: "\e236";
}

.glyphicon-modal-window:before {
    content: "\e237";
}

.glyphicon-oil:before {
    content: "\e238";
}

.glyphicon-grain:before {
    content: "\e239";
}

.glyphicon-sunglasses:before {
    content: "\e240";
}

.glyphicon-text-size:before {
    content: "\e241";
}

.glyphicon-text-color:before {
    content: "\e242";
}

.glyphicon-text-background:before {
    content: "\e243";
}

.glyphicon-object-align-top:before {
    content: "\e244";
}

.glyphicon-object-align-bottom:before {
    content: "\e245";
}

.glyphicon-object-align-horizontal:before {
    content: "\e246";
}

.glyphicon-object-align-left:before {
    content: "\e247";
}

.glyphicon-object-align-vertical:before {
    content: "\e248";
}

.glyphicon-object-align-right:before {
    content: "\e249";
}

.glyphicon-triangle-right:before {
    content: "\e250";
}

.glyphicon-triangle-left:before {
    content: "\e251";
}

.glyphicon-triangle-bottom:before {
    content: "\e252";
}

.glyphicon-triangle-top:before {
    content: "\e253";
}

.glyphicon-console:before {
    content: "\e254";
}

.glyphicon-superscript:before {
    content: "\e255";
}

.glyphicon-subscript:before {
    content: "\e256";
}

.glyphicon-menu-left:before {
    content: "\e257";
}

.glyphicon-menu-right:before {
    content: "\e258";
}

.glyphicon-menu-down:before {
    content: "\e259";
}

.glyphicon-menu-up:before {
    content: "\e260";
}


/* Twilio vers. styles */
.video-room-container {
    width: 100%;
    position: fixed;
    height: 100vh;
    background-color: black;
    overflow: hidden;
}

.video-room-container.select-recording video {
    cursor: url("../components/twilio/assets/images/21-2.png"), default;
}

.video-room-participant.local-video {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 120px;
    z-index: 2;
}

.video-room-participant {
    /*font-size: 0;
    border: solid 2px rgba(220, 220, 200, 0.7);
    position: relative;*/
}

.video-room-participant .participant-video-container {
    position: relative;
    background-color: black;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
}

.video-room-participant.is-been-recorded .participant-video-container::before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 8px;
    width: 14px;
    height: 14px;
    border-radius: 100px;
    background-color: #f00;
    animation: pulsating 1s infinite alternate-reverse;
}

@keyframes pulsating {
    from {
        opacity: 0.2;
    }
    to {
        opacity: 1;
    }
}

.video-room-participant video {
    width: 100%;
    height: 90px;
}

.video-room-participant.highlight {
    border: solid 2px #e00;
}

.video-room-participant.focused-video {
    /*border: none;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);*/

}

.video-room-participant.focused-video .participant-video-container {
    /*height: 100vh;*/
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 100%;
    max-height: 100%;
}

.video-room-participant.focused-video video {
    /*width: 100%;*/
    /*height: 100%;*/
    /*height: auto;*/
}

.focused-video video {
    height: 100vh;
}

.remote-videos {
    position: fixed;
    bottom: 50px;
    left: 5px;
    font-size: 0;
}

.remote-videos .video-room-participant {
    width: 120px;
    z-index: 2;
    margin: 5px 0;
}

.remote-videos .video-room-participant .participant-status {
    font-size: 0;
    color: red;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.remote-videos .video-room-participant .participant-status span {
    font-size: 20pt;
}

.identity-name-small {
    position: absolute;
    left: 2px;
    bottom: 2px;
    z-index: 1;
    color: white;
    background: #00000080;
    padding: 0px 5px;
    border-radius: 5px;
}

.identity-name-small span {
    font-size: 10pt;
}

.identity-name-big {
    position: fixed;
    left: 50%;
    top: 15px;
    z-index: 1;
    color: white;
    transform: translate(-50%);
    background: #00000080;
    border-radius: 5px;
}

.identity-name-big span {
    font-size: 15pt;
    padding: 10px;
}

.video-room-controls {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.video-room-controls button {
    font-size: 0;
    padding: 0;
    border: none;
    background-color: rgba(220, 220, 220, 0.75);
}

.barred:after {
    background: black !important;
}

.barred:hover:after {
    background: #0d6efd !important;
}

.video-room-controls button.first {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.video-room-controls button.last {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.video-room-controls button span {
    font-size: 22pt;
}

.video-room-controls button.device-selection-btn {
    position: relative;
}

.video-room-controls button.device-selection-btn span {
    font-size: 12pt;
}

.video-room-controls button.device-selection-btn .devicelist {
    position: absolute;
    font-size: 10pt;
    bottom: 100%;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateX(-50%);
}

.video-room-controls button.device-selection-btn .devicelist a {
    display: block;
    white-space: nowrap;
}

.video-room-controls button.device-selection-btn .devicelist a.current-device {
    color: #000;
}

.inspector-toolbar-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 14pt;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
}

.inspector-toolbar-btn:active {
    color: #0d6efd;
    background-color: #ededed;
    border-radius: 8px;
    transition: 0.3s
}

.participant-switch-cam {
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 10pt;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    padding: 1px 3px;
    z-index: 1;
}

.barred:after {
    position: absolute;
    content: "";
    background: white;
    display: block;
    width: 70%;
    height: 2px;
    border-radius: 15%;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: auto;
    transition: all 0.2s;
}

.inspector-toolbar-btn:hover.barred:after {
    background-color: #000;
}

.ant-popover {
    z-index: 9999 !important;
}

.ant-popover-hidden {
    display: none;
}

.inspector-lobby {
    width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: start;
}

.inspector-lobby-message {
    margin: 30px 0;
    font-size: 1.2em;
    text-align: center;
}

.inspector-lobby-room {
    margin-bottom: 30px;
}

.inspector-lobby-room input {
    padding: 7px 1rem;
    border: solid 1px rgb(204, 204, 204);
    border-radius: 4px;
    width: 100%;
}

.inspector-lobby-devices {
    margin-bottom: 20px;
}

.inspector-lobby-devices section {
    margin-bottom: 1rem;
}

.inspector-lobby-room section label {
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
    margin-top: 6px;
}

.inspector-lobby-room section label input {
    margin-right: 4px;
}

.inspector-lobby-room label {
    transition: color 1.4s;
}

.inspector-lobby-room label.error {
    color: #f00;
    transition: none;
}

.inspector-logo {
    margin: 0 0 30px 0;
}

/*----------INIZIO VIDEO CALL CHAT----------*/

.video-room-chat {
    /*position:absolute;*/
    position: fixed;
    width: 325px;
    height: 487px;
    left: 101%;
    z-index: 5;
}

.full-screen .video-room-chat {
    height: 60vh;
    top: 20vh;
    left: auto;
    right: 10vw;
}

#chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: rgb(248, 249, 250);
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: absolute;
    z-index: 1000;
    max-height: 550px;
    width: 300px;
    cursor: move;
}

.ant-input-group.ant-input-group-compact {
    display: flex;
    flex-wrap: nowrap;
}

@media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
    .inspector-lobby {
        width: 100%;
        padding: 1rem;
    }

    #chat-container {
        width: 100%;
        height: 100%;
        max-height: 100%;
    }

    #chat-container div.ant-form-item {
        flex-grow: 1;
    }

    .inspector-toolbar-btn {
        flex-grow: 1;
        height: 56px;
    }

    .room-controls-container {
        width: 95%;
    }

    .remote-videos {
        bottom: 60px;
    }
}

#chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ededed;
    padding: 1rem;
}

#chat-body {
    height: 100%;
    overflow-y: scroll;
    text-align: start;
    padding: 1rem;
    /*padding-bottom: 0;
    margin-bottom: 5rem;*/
}

.ant-comment {
    position: relative;
    background-color: white;
    padding: 0 0.5rem;
    border-radius: 8px;
    margin: 0.5rem 0;
}

.my-message {
    margin-left: 1rem;
    background-color: #e3f6ff;
}

.received-message {
    margin-right: 1rem;
    background-color: #ededed;
}

.ant-form label {
    display: flex;
}

.large-video {
    width: 100%;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
}

.room-controls-container {
    position: fixed;
    display: flex;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%);
    z-index: 5;
    border-radius: 8px;
    background-color: #fff;
    color: #000;
}

.exitBtn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    background-color: #9e0000;
    color: #fff;

}

.ant-popover-inner {
    border-radius: 8px;
}

.device-status-barred-focused:after {
    background: red !important;
    width: 35px;
    position: relative;
    left: 0px;
    top: -15px;
    content: "";
    background: white;
    display: block;
    height: 2px;
    transform: rotate(-45deg);
    margin: auto;
    transition: all 0.2s;
}

.device-status-barred:after {
    background: red !important;
    width: 20px;
    position: relative;
    left: 0px;
    top: -10px;
    content: "";
    background: white;
    display: block;
    height: 2px;
    transform: rotate(-45deg);
    margin: auto;
    transition: all 0.2s;
}

/*----------FINE VIDEO CALL CHAT----------*/